import React from "react"
import Layout from "../components/layout"
import SEO from "../utils/seo"

const Registre = () => (
  <Layout title="Registra't amb nosaltres">
    <SEO title="Registre" />
    <div style={{ textAlign: "center", marginTop: 50 }}>
      <iframe
        src="https://eepurl.com/dweO6D"
        width="800"
        title="registre"
        height="1450"
        frameBorder="0"
        marginWidth="0"
        marginHeight="0"
      ></iframe>
      
    </div>
  </Layout>
)

export default Registre
